import { css } from "styled-components";

const flexLayouts = {
  flexStart: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,

  flexCenter: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  flexColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  flexColumnSpacebetween: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
  `,

  flexColumnStart: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  flexBetween: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  flexAround: css`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  `,

  flexEven: css`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  `,

  flexBeside: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
}

const components = {
  button: css`
    width: fit-content;
    ${flexLayouts.flexCenter}
    gap: 0.3em;
    font-size: var(--fz-xxs);
    font-weight: 400;
    appearance: button;
    backface-visibility: hidden;
    background-color: ${({ theme }) => theme.colors.btncolor};
    border: 1.8px solid ${({ theme }) => theme.colors.border};
    border-radius: 6px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.descfont};
    cursor: pointer;
    outline: none;
    overflow: hidden;
    padding: 0.3em 0.6em;
    position: relative;
    text-align: center;
    text-transform: none;
    /* transform: translateZ(0); */
    /* transition: all 0.4s ease; */
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    :hover {
      text-decoration: 1.5px underline ${({ theme }) => theme.colors.secondary};
    }

    :active {
      text-decoration: 1.5px underline ${({ theme }) => theme.colors.secondary};
    }

    :focus {
      text-decoration: 1.5px underline ${({ theme }) => theme.colors.secondary};
    }

    @media (min-width: 400px) {
    }

    @media (min-width: 800px) {
      padding: 0.5em 1em;
      font-size: var(--fz-sm);
    }
  `,

  circleButton: css`
    background: inherit;
    background-clip: padding-box;
    border: var(--border-width-hardest) solid ${({ theme }) => theme.colors.border};
    border-radius: 2em;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    padding: 0.3em 1em;
    position: relative;
    text-align: center;
    text-transform: none;
    font-size: var(--fz-xs);
    font-weight: 300;
    color: ${({ theme }) => theme.colors.descfont};

        @media (min-width: 400px) {
    }

    @media (min-width: 800px) {
      padding: 0.5em 1.5em;
      font-size: var(--fz-sm);
    }
  `,

  link: css`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.secondary};
    text-decoration-thickness: auto;
    text-decoration-thickness: var(--border-width);
    text-underline-offset: 2px;

    :hover{
      text-decoration-thickness: 2px;
    }
  `,

  list: css`
    counter-reset: item 0;
    color: ${({ theme }) => theme.colors.descfont};
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      position: relative;
      counter-increment: item 1;
      /* font-size: var(--fz-xs); */
      &:before {
        font-family: monospace;
        content: "0" counter(item) ".";
        margin-right: 5px;
        /* color: var(--green); */
        /* font-size: var(--fz-xxs); */
        text-align: right;
     }

      h3 {
        font-weight: 500;
     }

      p {
        font-weight: 300;
      }
    }
  `
}

const Layouts = {
  squareGrid: css`
  display: grid;
  grid-template-columns: 1fr;
  /* margin: 1.5em 0; */
  /* gap: 2em; */
  align-items: start;
  grid-template-rows: 1fr;
  grid-gap: 0;

  // grid filling should begin with center firrst and then go to left and right
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em;
    border-left: var(--border-width-hard) solid
      ${({ theme }) => theme.colors.dborder};
    border-right: var(--border-width-hard) solid
      ${({ theme }) => theme.colors.dborder};
    border-bottom: var(--border-width-hard) solid
      ${({ theme }) => theme.colors.dborder};
    height: 100%;
  }


  div:nth-last-child(-n + 1):nth-child(3n + 3) {
    border-bottom: none;
    /* background-color: pink; */
  }

  @media (min-width: 800px) {
  
      > div:nth-child(3n) {
    border-left: none;
    /* border-right: none; */
    /* background-color: pink; */
  }

    > div:nth-child(3n + 1) {
    border-right: none;
    /* border-left: none; */
    /* background-color: salmon; */
  }
  div:nth-last-child(-n + 3):nth-child(3n + 1),
  div:nth-last-child(-n + 2):nth-child(3n + 2),
  div:nth-last-child(-n + 1):nth-child(3n + 3) {
    border-bottom: none;
    /* background-color: pink; */
  }

  }

  /* > div:nth-last-child(-n + 3):nth-last-child(1):nth-child(3n) {
    border-bottom: none;
    background-color: pink;
  } */
  `,
}

const mixins = {
  flex: flexLayouts,
  components: components,
  layouts: Layouts,
  iconWithText: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    svg{
      width: 1.1rem;
      height: 1.1rem;
    } 
  `
}



export default mixins;
