import { navLinks } from "@/utils/static";
import { Link } from "gatsby";
// import { globalHistory } from "@reach/router";
import { useLocation } from "@reach/router";
import React from "react";
import { Moon, Sun } from "react-feather";
import styled from "styled-components";
import blinkingarsh from "../../images/blinkingarsh.gif";

interface IStyledLinkProps {
  active: boolean;
}

const StyledNavChild = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  height: 100%;
  border-left: var(--border-width-hard) solid
    ${({ theme }) => theme.colors.dborder};
  border-right: var(--border-width-hard) solid
    ${({ theme }) => theme.colors.dborder};
  border-bottom: var(--border-width-hard) solid
    ${({ theme }) => theme.colors.dborder};
  padding: 0.5em 0;

  @media (min-width: 800px) {
    padding: 1em 0;
  }

  button {
    /* text-decoration: underline; */
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  #name {
    text-decoration: none;
    font-family: var(--font-serif-ogg);
    font-weight: 400;
  }
`;

const StyledNav = styled.nav`
  ${({ theme }) => theme.mixins.flex.flexBetween};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background};
  background-image: url(${({ theme }) => theme.imgs.grain});
  background-size: 300px;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};

  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  transition: var(--transition);
  /* font-family: var(--font-serif-ogg);
  font-weight: 200; */
  font-size: var(--fz-lg);
  font-style: normal;
  counter-reset: item 0;
  z-index: 10;
  height: 100px;
  text-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;

  display: -ms-grid;

  display: grid;
  -ms-grid-columns: 1fr 0px 1fr 0px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0px;

  ${StyledNavChild}:nth-child(1) {
    p {
      font-family: var(--font-serif-ogg);
      font-weight: 500;
      font-size: var(--fz-xl);
      display: block;
    }
    border-left: none;
    grid-area: 1/1/2/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    overflow: hidden;
    /* position: relative; Add this */

    img {
      display: none;
      width: 50%;
      height: auto;
      /* position: absolute; /* Add this */
      display: none; /* Add this */
      transition: opacity 0.3s ease;
      opacity: 0;
      object-fit: fill;
    }

    &:hover {
      /* Add this */
      img {
        display: inline;
        opacity: 1;
        transition: opacity 0.3s ease;
      }
      p {
        display: none;
      }
    }
    /* background-color: lightskyblue; */
  }

  ${StyledNavChild}:nth-child(2) {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border-left: none;
    border-right: none;
    border-top: none;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-area: 2/1/3/4;
    overflow-x: auto;
    overflow-y: hidden;
  }

  ${StyledNavChild}:nth-child(3) {
    font-weight: 400;
    border-right: none;
    border-left: none;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    grid-area: 1/3/2/4;
  }

  @media (min-width: 350px) {
    ${StyledNavChild}:nth-child(2) {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (min-width: 800px) {
    height: 70px;
    -ms-grid-columns: 1fr 0px 3fr 0px 1fr;
    grid-template-columns: 1fr 3fr 1fr;
    /* grid-template-rows: 1fr; */
    ${StyledNavChild}:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      grid-area: 1/1/2/2;
    }

    ${StyledNavChild}:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
      grid-area: 1/2/2/3;
    }

    ${StyledNavChild}:nth-child(3) {
      border-left: var(--border-width-hard) solid
        ${({ theme }) => theme.colors.dborder};
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
      grid-area: 1/3/2/4;
    }
  }
  /* @media (min-width: 1000px) {
    width: 70%;
  } */

  ::after {
    display: none;
  }

  .logo {
    ${({ theme }) => theme.mixins.flex.flexCenter};
    a {
      color: var(--green);
      text-decoration: none;
      color: inherit;
      &:hover,
      &:focus {
        svg {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
      svg {
        fill: none;
        transition: var(--transition);
        user-select: none;
      }
    }
  }
`;

const StyledLinksDiv = styled.div`
  display: flex;
  align-items: center;
  ol {
    ${({ theme }) => theme.mixins.flex.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;
    li {
    }
  }
`;

const StyledLinkComp = styled.li<IStyledLinkProps>`
  --active-color: ${({ theme }) => theme.colors.primary};
  --normal-color: ${({ theme }) => theme.colors.descfont};
  --underline: 1.5px underline ${({ theme }) => theme.colors.secondary};

  margin: 0 5px;
  position: relative;
  counter-increment: item 1;
  font-size: var(--fz-xs);
  color: ${(props) =>
    props.active ? "var(--active-color)" : "var(--normal-color)"};
  text-decoration: ${(props) => (props.active ? "var(--underline)" : "none")};
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  a {
    padding: 10px;
    text-decoration: none;
    color: inherit;
    &:before {
      /* content: "0" counter(item) "."; */
      margin-right: 5px;
      color: ${({ theme }) => theme.colors.descfont};
      font-size: var(--fz-xxs);
      text-align: right;
    }
  }
`;

const StyleAlign = styled.div`
  ${({ theme }) => theme.mixins.iconWithText};
`;

const Navbar = ({
  toggleTheme,
  currentTheme,
}: {
  toggleTheme: () => void;
  currentTheme: string;
}) => {
  const [current, setCurrent] = React.useState<any>();
  const location = useLocation();

  React.useEffect(() => {
    let currentPath = "";
    const regex = /^\/([^/]+)/;
    const str = location.pathname;

    const match = str.match(regex);

    if (match) {
      const word = match[0];
      currentPath = word as string;
    } else {
      currentPath = "/";
    }
    setCurrent(currentPath);
  }, [current, location.pathname]);

  return (
    <StyledNav>
      <StyledNavChild>
        <Link
          to="/"
          id="name"
          style={{ textDecoration: "none", fontWeight: "500" }}
        >
          <img src={blinkingarsh} alt="Harsh Patel" />
          <p>Harsh Patel</p>
          {/* <GatsbyImage
            image={blinkingarsh}
            alt="Harsh Patel"
            style={{ width: "100%", height: "100%" }}
          /> */}
        </Link>
      </StyledNavChild>
      <StyledNavChild>
        <StyledLinksDiv>
          <ol>
            {navLinks &&
              navLinks.map(({ url, name }, i) => (
                <StyledLinkComp active={current === url} key={i}>
                  <Link to={url}>{name}</Link>
                </StyledLinkComp>
              ))}
          </ol>
        </StyledLinksDiv>
      </StyledNavChild>
      <StyledNavChild>
        <button style={{ width: "fit-content" }} onClick={toggleTheme}>
          <StyleAlign>
            {currentTheme === "light" ? <Moon /> : <Sun />}
          </StyleAlign>
        </button>
      </StyledNavChild>
    </StyledNav>
  );
};

export default Navbar;
