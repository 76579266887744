export const navLinks = [
    {
        name: "About",
        url: "/about",
        key: 2,
    },
    {
        name: "Projects",
        url: "/projects",
        key: 3,
    },
    {
        name: "Blogs",
        url: "/blogs",
        key: 4,
    },
    {
        name: "Contact",
        url: "/contact",
        key: 5,
    },
    {
        name: "Misc",
        url: "/misc",
        key: 6,
    },
]

export const socials = [
    {
        name: "Github",
        url: "https://github.com/harshptl14",
        key: 1,
    },
    {
        name: "X(Bird app)",
        url: "https://x.com/harshptl14",
        key: 2,
    },
    {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/harshptl14/",
        key: 4,

    },
    {
        name: "Email",
        url: "mailto:howdy.harsh@gmail.com",
        key: 5,
    },
]