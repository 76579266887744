import { DefaultTheme } from 'styled-components';
import mixins from './mixins';
import grain from "../images/bg-grain-dark.png";
import grainDark from "../images/bg-grain.png";
import toolpooldark from '../images/homescreenImages/toolpooldark.png'
import toolpoollight from '../images/homescreenImages/toolpoollight.png'
import twitterdark from '../images/homescreenImages/twitterdark.png'
import twitterlight from '../images/homescreenImages/twitterlight.png'

export const darkTheme: DefaultTheme = {
    colors: {
        primary: "#ffb39c",
        secondary: "#17875a",
        // primary: "#ffffff",
        accent: "rgba(167, 104, 255, 0.3)",
        Iback: "rgba(20, 48, 63, 1)",
        border: "rgba(93,115,163,0.3)",
        // dborder: "#5d73a3",
        dborder: "rgba(93, 115, 163, 0.5)",
        background: "#14303f",
        descfont: "rgb(204,143,124, 0.8)",
        btncolor: "rgba(0,0,0,0.2)",
        imgcolor: "#AAA1A0",
        gradientone: "rgb(20, 48, 63, 0.5)",
        gradienttwo: "rgb(170, 161, 160, 0.5)",
    },
    imgs: {
        grain: grain,
        toolpool: toolpooldark,
        twitter: twitterdark,
    },
    mixins: mixins,
    mode: "dark"
};

export const lightTheme: DefaultTheme = {
    colors: {
        primary: "#14304f",
        secondary: "#17875a",
        // primary: "#000000",
        accent: "rgba(167, 104, 255, 0.3)",
        Iback: "rgba(235, 236, 240, 1)",
        border: "rgba(181,186,198,0.3)",
        // dborder: "#8495b9",
        dborder: "rgba(132, 149, 185, 0.5)",
        background: "#ebecf0",
        descfont: "rgb(84,97,104,0.85)",
        btncolor: "rgba(20,48,63,0.08)",
        imgcolor: "#A4A9B7",
        gradientone: "rgb(235,236,240, 0.3)",
        gradienttwo: "rgb(20,48,63, 0.5)",
    },
    imgs: {
        grain: grainDark,
        toolpool: toolpoollight,
        twitter: twitterlight,
    },
    mixins,
    mode: "light"
}