import { css } from 'styled-components';

import OggLightTtf from '@fonts/Ogg/Ogg-Light.ttf';
import OggRegularTtf from '@fonts/Ogg/Ogg-Regular.ttf';
import OggMediumTtf from '@fonts/Ogg/Ogg-Medium.ttf';
import OggBoldTtf from '@fonts/Ogg/Ogg-Bold.ttf';
import OggExtraboldTtf from '@fonts/Ogg/OggText-Extrabold.ttf';


import OggLightItalicTtf from '@fonts/Ogg/Ogg-LightItalic.ttf';
import OggRegularItalicTtf from '@fonts/Ogg/Ogg-RegularItalic.ttf';
import OggMediumItalicTtf from '@fonts/Ogg/Ogg-MediumItalic.ttf';
import OggBoldItalicTtf from '@fonts/Ogg/Ogg-BoldItalic.ttf';
import OggExtraboldItalicTtf from '@fonts/Ogg/OggText-ExtraboldItalic.ttf';

// const gtsuperNormalWeights = {
//     300: [GTSuperLightOtf],
//     400: [GTSuperRegularOtf],
//     500: [GTSuperMediumOtf],
//     600: [GTSuperBoldOtf],
//     700: [GTSuperExtraboldOtf],
// };

// const gtsuperItalicWeights = {
//     300: [GTSuperLightItalicOtf],
//     400: [GTSuperRegularItalicOtf],
//     500: [GTSuperMediumItalicOtf],
//     600: [GTSuperBoldItalicOtf],
//     700: [GTSuperExtraBoldItalicOtf],
// };

const oggNormalWeights = {
  300: [OggLightTtf],
  400: [OggRegularTtf],
  500: [OggMediumTtf],
  600: [OggBoldTtf],
  700: [OggExtraboldTtf],
};

const oggItalicWeights = {
  300: [OggLightItalicTtf],
  400: [OggRegularItalicTtf],
  500: [OggMediumItalicTtf],
  600: [OggBoldItalicTtf],
  700: [OggExtraboldItalicTtf],
};

// const gtsuper = {
//     name: 'GT Super',
//     normal: gtsuperNormalWeights,
//     italic: gtsuperItalicWeights,
// };

const ogg = {
  name: 'Ogg',
  normal: oggNormalWeights,
  italic: oggItalicWeights,
};

const createFontFaces = (family, style = 'normal') => {
  let styles = '';

  for (const [weight, formats] of Object.entries(family[style])) {
    const ttf = formats[0];
    // const otf = formats[1];

    styles += `
      @font-face {
        font-family: '${family.name}';
        src: url(${ttf}) format('truetype');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
  }

  return styles;
};

// const calibreNormal = createFontFaces(calibre);
// const calibreItalic = createFontFaces(calibre, 'italic');

const oggNormal = createFontFaces(ogg);
const oggItalic = createFontFaces(ogg, 'italic');

const Fonts = css`
  ${oggNormal + oggItalic}
`;

export default Fonts;
