import { useEffect, useState } from "react";

export const useDarkMode = (): [string, () => void] => {
  const isBrowser = typeof window !== "undefined";
  const [theme, setTheme] = useState("light");

  const setMode = (mode: any) => {
    if (isBrowser) {
      window.localStorage.setItem("theme", mode);
    }
    setTheme(mode);
  };

  const themeToggler = (): void =>
    theme === "light" ? setMode("dark") : setMode("light");

  useEffect(() => {
    let localTheme;
    if (isBrowser) {
      localTheme = window.localStorage.getItem("theme");
    }
    localTheme && setTheme(localTheme);
  }, []);
  return [theme, themeToggler];
};
