import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Analytics } from "@vercel/analytics/react";
import { darkTheme, GlobalStyle, lightTheme } from "@/styles";
import { useDarkMode } from "@/hooks/useDarkmode";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import Popup from "../Reusable/Popup";
// import { usePopup } from "@/hooks/usePopup";

const StyledLayout = styled.div`
  /* display: flex;
    flex-direction: column;
    margin: 2em 12em;

     @media (max-width: 996px) {
        margin: 2em 9em;
    }

    @media (max-width: 768px) {
        margin: 2em 6em;
    }

    @media (max-width: 480px) {
        margin: 2em 3em;
    } */
  /* display: flex;
  flex-direction: column; */
  width: 90%;
  /* background-color: pink; */
  max-width: 1030px;
  margin: 0 auto;
  /* flex-grow: 1; */
  flex: 1 0 auto;
  /* @media (min-width: 600px) {
    width: 85%;
  }
  @media (min-width: 900px) {
    width: 80%;
  }
  @media (min-width: 1276px) {
    width: 75%;
  } */

  /* @media (min-width: 600px) {
    width: 90%;
  } */
  @media (min-width: 1000px) {
    width: 90%;
  }

  @media (min-width: 1200px) {
    width: 90%;
  }
`;

const Layout = ({ children }: any) => {
  const [theme, themeToggler] = useDarkMode();
  // const [popup, setPopup] = usePopup();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <>
      <ThemeProvider theme={themeMode}>
        <Navbar toggleTheme={themeToggler} currentTheme={theme} />
        <GlobalStyle />
        <Analytics />
        <StyledLayout>
          {/* <button onClick={themeToggler}>toggle</button> */}
          {children}
        </StyledLayout>
        <Footer />
        {/* {popup && (
          <Popup title="🚧 Under Construction" onClick={() => setPopup()}>
            <p>
              Something awesome is brewing. Stay up to date for the final
              version!
            </p>
          </Popup>
        )} */}
      </ThemeProvider>
    </>
  );
};

export default Layout;
