import { socials } from "@/utils/static";
import React from "react";
import styled from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const FooterWrapper = styled.footer`
  ${({ theme }) => theme.mixins.flex.flexColumn};
  flex-shrink: 0;
  padding: 1.5em 0;
  width: 100%;
  /* margin-top: 3em; */
  border-top: var(--border-width-hard) solid
    ${({ theme }) => theme.colors.dborder};

  @media (min-width: 900px) {
    ${({ theme }) => theme.mixins.flex.flexBetween};
    padding: 1.3em 2em;
  }
`;

const StyledLinksDiv = styled.div`
  display: flex;
  align-items: center;
  ol {
    ${({ theme }) => theme.mixins.flex.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-right: 1em;

      :hover {
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.secondary};
        text-decoration-thickness: auto;
        text-decoration-thickness: var(--border-width);
        text-underline-offset: 2px;
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <div>Made with 💜 by Harsh</div>
      <StyledLinksDiv>
        <ol>
          {socials &&
            socials.map(({ url, name }) => (
              <OutboundLink href={url} target="_blank">
                <li>{name}</li>
              </OutboundLink>
            ))}
        </ol>
      </StyledLinksDiv>
    </FooterWrapper>
  );
};

export default Footer;
