import { createGlobalStyle } from "styled-components";
import Fonts from "./fonts";
import variables from "./variables";


const GlobalStyle = createGlobalStyle`
  ${Fonts}
  ${variables}  
  html{
      box-sizing: border-box;
      width: 100%;
      scroll-behavior: smooth;
      scroll-padding-top: 100px;
      /* transition: var(--transition); */
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  *, 
    *:before,
    *:after {
      margin: 0;
      padding: 0;
      box-sizing: inherit;
      font-family: 'Poppins', -apple-system, system-ui, sans-serif;
    }

    /* @font-face {
      font-family: 'GTSuper';
      src: url('/fonts/GT-Super-Display-Regular.otf') format('opentype');
      font-weight: 400;
      font-style: normal;
    }
    
    @font-face {
      font-family: 'Ogg';
      src: url('/fonts/Ogg-Light.ttf') format('truetype');
      font-weight: 100;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ogg';
      src: url('/fonts/Ogg-Regular.ttf') format('truetype');
      font-weight: 200;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ogg';
      src: url('/fonts/Ogg-Medium.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ogg';
      src: url('/fonts/Ogg-Bold.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
    }

    @font-face {
      font-family: 'Ogg';
      src: url('/fonts/OggText-Bold.ttf') format('truetype');
      font-weight: 500;
      font-style: bolder;
    } */

    /* Scrollbar Styles */
  html {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.dborder};
  }

    ::selection {
    transition: var(--transition);
    background-color: ${({ theme }) => theme.colors.dborder};
    color: ${({ theme }) => theme.colors.background};
  }
  /* :focus {
    outline: 1.8px dashed ${({ theme }) => theme.colors.primary};
    outline-offset: 3px;
  } */
  body::-webkit-scrollbar {
    width: 10px;
  }
  body::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.border};
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.dborder};
    /* border: 1px solid ${({ theme }) => theme.colors.dborder}; */
  }

  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size:  1rem;
    background:  url(${({ theme }) => theme.imgs.grain});
    background-size: 300px;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.primary};
    font-size: var(--fz-sm);

    @media (min-width: 400px) {
      font-size: var(--fz-md);
    }
  }

  #root, #gatsby-focus-wrapper{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

`;

export default GlobalStyle;
