exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-misc-clicks-tsx": () => import("./../../../src/pages/misc/clicks.tsx" /* webpackChunkName: "component---src-pages-misc-clicks-tsx" */),
  "component---src-pages-misc-index-tsx": () => import("./../../../src/pages/misc/index.tsx" /* webpackChunkName: "component---src-pages-misc-index-tsx" */),
  "component---src-pages-misc-sketches-tsx": () => import("./../../../src/pages/misc/sketches.tsx" /* webpackChunkName: "component---src-pages-misc-sketches-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */),
  "component---src-templates-blog-tsx-content-file-path-content-blogs-how-i-made-this-portfolio-index-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/vercel/path0/content/blogs/How-I-made-this-portfolio/index.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-blogs-how-i-made-this-portfolio-index-mdx" */),
  "component---src-templates-blog-tsx-content-file-path-content-blogs-how-to-prep-for-sa-index-mdx": () => import("./../../../src/templates/blog.tsx?__contentFilePath=/vercel/path0/content/blogs/How-to-prep-for-SA/index.mdx" /* webpackChunkName: "component---src-templates-blog-tsx-content-file-path-content-blogs-how-to-prep-for-sa-index-mdx" */),
  "component---src-templates-job-tsx-content-file-path-content-experience-augmetic-augmetic-mdx": () => import("./../../../src/templates/job.tsx?__contentFilePath=/vercel/path0/content/experience/augmetic/augmetic.mdx" /* webpackChunkName: "component---src-templates-job-tsx-content-file-path-content-experience-augmetic-augmetic-mdx" */),
  "component---src-templates-job-tsx-content-file-path-content-experience-pragnakalp-pragnakalp-mdx": () => import("./../../../src/templates/job.tsx?__contentFilePath=/vercel/path0/content/experience/pragnakalp/pragnakalp.mdx" /* webpackChunkName: "component---src-templates-job-tsx-content-file-path-content-experience-pragnakalp-pragnakalp-mdx" */),
  "component---src-templates-job-tsx-content-file-path-content-experience-simform-simform-mdx": () => import("./../../../src/templates/job.tsx?__contentFilePath=/vercel/path0/content/experience/simform/simform.mdx" /* webpackChunkName: "component---src-templates-job-tsx-content-file-path-content-experience-simform-simform-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-adgroup-adgroup-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/adgroup/adgroup.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-adgroup-adgroup-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-bookmybook-bookmybook-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/bookmybook/bookmybook.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-bookmybook-bookmybook-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-clubgamma-clubgamma-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/clubgamma/clubgamma.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-clubgamma-clubgamma-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-collegespace-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/collegespace/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-collegespace-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-exploria-exploria-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/exploria/exploria.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-exploria-exploria-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-speck-speck-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/speck/speck.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-speck-speck-mdx" */),
  "component---src-templates-project-tsx-content-file-path-content-projects-main-toolpool-index-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/vercel/path0/content/projects/main/toolpool/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-content-projects-main-toolpool-index-mdx" */)
}

